<template>
  <div class="container">
    <div class="columns is-centered is-mobile">
      <div class="column is-10-mobile is-5-tablet">
        <section class="login-card card">
          <div class="card-content flex flex-col items-center text-center">
            <img class="logo" src="@/assets/logo.png" />
            <h1 class="has-text-weight-bold is-size-5">
              Join and start building<br />your own site
            </h1>
          </div>
          <div class="flex flex-col items-center p-4 bg-gray-300">
            <google-sign-in-button @click="signIn" />
            <div class="mt-4 text-xs">
              By signing in, you agree to our
              <a class="underline" href="https://getbiew.com/privacy">
                Privacy Policy
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseAuth from "@/plugins/firebase/auth";
import GoogleSignInButton from "@/components/GoogleSignInButton";

export default {
  name: "Login",

  components: {
    GoogleSignInButton
  },

  data() {
    return {
      email: null,
      password: null,
      loading: false
    };
  },
  mounted() {
    // Verificar login
    const currentUser = firebaseAuth.currentUser;
    if (currentUser) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    signIn() {
      this.$store
        .dispatch("signin")
        .then(() => {
          console.log("signed in");
        })
        .catch(() => {
          console.log("error signing in");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-card {
  margin-top: 250px;
}

h1 {
  margin-top: 1em;
}

.logo {
  width: 100px;
}

.login-button {
  height: 60px;

  .icon {
    height: 100%;
  }

  span {
    margin-left: 1em;
  }
}
</style>
