import { render, staticRenderFns } from "./GoogleSignInButton.vue?vue&type=template&id=fd97f6dc&scoped=true&"
import script from "./GoogleSignInButton.vue?vue&type=script&lang=js&"
export * from "./GoogleSignInButton.vue?vue&type=script&lang=js&"
import style0 from "./GoogleSignInButton.vue?vue&type=style&index=0&id=fd97f6dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd97f6dc",
  null
  
)

export default component.exports